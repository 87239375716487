<template>
  <div class="fourteen wide column">
    <div
      id="message_info"
      class="fullwidth"
    >
      <div v-if="infoMessage.length > 0">
        <div
          v-for="(message, index) of infoMessage"
          :key="index"
          :class="['ui message', message.success ? 'positive' : 'negative']"
          style="text-align: left"
        >
          <div class="header">
            <i class="info circle icon" /> Informations
          </div>
          {{ message.comment }}
        </div>
      </div>
    </div>
    <h1 class="ui header">
      Administration des fonds cartographiques
    </h1>

    <form
      id="form-layers"
      action="."
      method="post"
      enctype="multipart/form-data"
      class="ui form"
    >
      <!--  {{ formset.management_form }} -->
      <div class="ui buttons">
        <a
          class="ui compact small icon left floated button green"
          data-variation="mini"
          @click="addBasemap"
        >
          <i class="ui plus icon" />
          <span>&nbsp;Créer un fond cartographique</span>
        </a>
      </div>

      <div
        v-if="basemaps"
        class="ui"
      >
        <ProjectMappingBasemap
          v-for="basemap in basemaps"
          :key="basemap.id"
          :basemap="basemap"
        />
      </div>
      <div class="margin-top">
        <button
          type="button"
          class="ui teal icon floated button"
          @click="saveChanges"
        >
          <i class="white save icon" /> Enregistrer les changements
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Project_mapping_basemap from '@/components/project/project_mapping_basemap.vue';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'ProjectMapping',

  components: {
    ProjectMappingBasemap: Project_mapping_basemap,
  },

  data() {
    return {
      infoMessage: [],
      newBasemapIds: [],
    };
  },

  computed: {
    ...mapState('map', ['basemaps']),
    ...mapGetters('map', ['basemapMaxId']),
  },

  created() {
    if (!this.$store.state.projects.project) {
      this.$store.dispatch('projects/GET_PROJECT', this.$route.params.slug);
      this.$store.dispatch('projects/GET_PROJECT_INFO', this.$route.params.slug);
    }
  },

  methods: {
    addBasemap() {
      this.newBasemapIds.push(this.basemapMaxId + 1); //* register new basemaps to seperate post and put
      this.$store.commit('map/CREATE_BASEMAP', this.basemapMaxId + 1);
    },
    
    checkTitles() {
      let isValid = true;
      this.basemaps.forEach((basemap) => {
        if (basemap.title === null || basemap.title === '') { //* check title when saving basemaps
          basemap.errors = 'Veuillez compléter ce champ.';
          isValid = false;
        } else if (basemap.layers.length === 0) {
          basemap.errors = 'Veuillez ajouter au moins un layer.';
          isValid = false;
        } else {
          basemap.errors = '';
        }
      });
      return isValid;
    },

    saveChanges() {
      if (this.checkTitles()) {
        this.$store
          .dispatch('map/SAVE_BASEMAPS', this.newBasemapIds)
          .then((response) => {
            const errors = response.filter(
              (res) =>
                res.status === 200 && res.status === 201 && res.status === 204
            );
            if (errors.length === 0) {
              this.infoMessage.push({
                success: true,
                comment: 'Enregistrement effectué.',
              });
              this.newBasemapIds = [];
            } else {
              this.infoMessage.push({
                success: false,
                comment: "L'édition des fonds cartographiques a échoué. ",
              });
            }

            document
              .getElementById('message_info')
              .scrollIntoView({ block: 'end', inline: 'nearest' });
            setTimeout(
              function () {
                this.infoMessage = [];
              }.bind(this),
              5000
            );
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
  },
};
</script>