<template>
  <div
    :id="layer.dataKey"
    class="ui segment layer-item"
  >
    <div class="ui divided form">
      <div
        class="field"
        data-type="layer-field"
      >
        <label
          for="form.layer.id_for_label"
          class="layer-handle-sort"
        >
          <i class="th icon" />couche
        </label>
        <Dropdown
          :options="availableLayerOptions"
          :selected="selectedLayer.name"
          :selection.sync="selectedLayer"
          :search="true"
          :placeholder="placeholder"
        />
      </div>
      <div class="fields">
        <div
          class="field three wide {% if form.opacity.errors %} error{% endif %}"
        >
          <label for="opacity">Opacité</label>
          <input
            v-model.number="layerOpacity"
            type="number"
            oninput="validity.valid||(value='');"
            step="0.01"
            min="0"
            max="1"
          >
        </div>
        <div class="field three wide">
          <div
            class="ui checkbox"
            @click="updateLayer({ ...layer, queryable: !layer.queryable })"
          >
            <input
              :checked="layer.queryable"
              class="hidden" 
              type="checkbox"
              name="queryable"
            >
            <label for="queryable"> Requêtable</label>
          </div>
        </div>
      </div>

      <div
        class="field"
        @click="removeLayer"
      >
        <div class="ui compact small icon floated button button-hover-red">
          <i class="ui grey trash alternate icon" />
          <span>Supprimer cette couche</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from '@/components/Dropdown.vue';
import { mapState } from 'vuex';

export default {
  name: 'ProjectMappingContextLayer',

  components: {
    Dropdown,
  },

  props: {
    layer:
     {
       type: Object,
       default: null,
     },
    basemapid:
      {
        type: Number,
        default: null,
      },
  },

  computed: {
    ...mapState('map', ['availableLayers']),

    selectedLayer: {
      get() {
        return this.retrieveLayer(this.layer.title) || [];
      },

      set(newValue) {
        const matchingLayer = this.retrieveLayer(newValue.title);
        if (matchingLayer !== undefined) {
          this.updateLayer({
            ...this.layer,
            service: newValue.name,
            title: newValue.value,
            id: matchingLayer.id,
          });
        }
      },
    },

    layerOpacity: {
      get() {
        return this.layer.opacity;
      },
      set(newValue) {
        if (newValue) {
          //* check if value was filled
          this.updateLayer({ ...this.layer, opacity: newValue });
        }
      },
    },

    availableLayerOptions: function () {
      return this.availableLayers.map((el) => {
        return {
          id: el.id,
          name: `${el.title} - ${el.service}`,
          value: el.title,
          title: el.title,
        };
      });
    },

    placeholder: function () {
      return this.selectedLayer && this.selectedLayer.name
        ? ''
        : 'Choisissez une couche';
    },
  },

  mounted() {
    const matchingLayer = this.retrieveLayer(this.layer.title);
    if (matchingLayer !== undefined) {
      this.updateLayer({
        ...this.layer,
        service: matchingLayer.service,
        title: matchingLayer.title,
        id: matchingLayer.id,
      });
    }
  },

  methods: {
    retrieveLayer(title) {
      return this.availableLayerOptions.find((el) => el.title === title);
    },

    removeLayer() {
      this.$store.commit('map/DELETE_BASEMAP_LAYER', {
        basemapId: this.basemapid,
        layerId: this.layer.dataKey,
      });
    },

    updateLayer(layer) {
      this.$store.commit('map/UPDATE_BASEMAP_LAYER', {
        basemapId: this.basemapid,
        layerId: this.layer.dataKey,
        layer,
      });
    },
  },
};
</script>